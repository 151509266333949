import React from 'react';

import Layout from '../components/layout/Layout';


const siteVerOpts = [

  {
    label: 'Ecuador',
    value: '',


  },

  {
    label: 'Internacional',
    value: '/export-es/',


  },
];

const langOpts = [


  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: `/export/en/social-responsability`,

  },


];

const seo={
  title: "Nuestra política de estándares laborales",
  desc:  "Descubre nuestras políticas de estándares laborales",
  image: "",
  pathname: "/politica-estandares-laborales/",
}

 const EstandaresLaborales = ({location}) =>  (

  <Layout location={location} crumbLabel="Politica de estandares laborales"
  siteVerOpts={siteVerOpts} langOpts={langOpts}
  title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>


<section id="" className="p-10">


    <div className="container font-serif">
        <h1 className="text-lg font-bold">Nuestras Políticas de Estándares Laborales</h1>
    <p>• Unilimpio cumple cabalmente con las leyes y normas laborales nacionales. <br/>
•	Unilimpio realiza los procesos de selección basados en un perfil profesional y en competencias, sin discriminación. <br/>
•	Unilimpio no permite el empleo de mano de obra infantil en ninguno de sus procesos.  <br/>
•	En Unilimpio no se realiza ninguna clase de trabajo forzoso u obligatorio, para lo cual la empresa trabaja con un Código de ética, contratos de trabajo y promueve la Seguridad y Salud Ocupacional, con el fin de evitar accidentes o enfermedades ocupacionales.  <br/>
•	Unilimpio ofrece canales de comunicación directa con sus trabajadores, ya sea en forma individual o colectiva, con el fin de atender sus necesidades.  <br/>
•	Unilimpio realiza una autoevaluación sobre el cumplimiento de las políticas laborales fundamentales, trabajo infantil y prácticas para evitar la discriminación, al menos una vez al año.  <br/>
•	Unilimpio protege a todos los colaboradores y con mayor énfasis a la población vulnerable (mujeres embarazadas, personas con alguna discapacidad, adultos mayores, entre otros). <br/>
•	Unilimpio respeta y garantiza el derecho a la desconexión del trabajador
 <br/>

</p>



    </div>



</section>

  

  </Layout>
);

export default EstandaresLaborales;
